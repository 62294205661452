.main-nav {
  font-size: $f16;
  font-weight: 700;
  justify-self: flex-start;
  height: auto;
  min-height: 110px;

  & .nav-item {
    position: relative;
    
    & .submenu {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      display: none;

      width: 250px;
      padding-top: 5px;
      z-index: 99999999999 !important;

      & li {
        padding: 10px 20px;
        background: $dark-color;
        // border-bottom: 1px solid #525252;

        & a {
          color: $white-color;
        }

        &:first-child {
          padding-top: 20px;
        }
        &:last-child {
          padding-bottom: 30px;
        }
      }
    }
    &:hover {
      & > .submenu {
        @media all and (min-width:#{$tablet}) {
          display: inline-block !important;
        }
      }
    }
  }
  & .nav-link {
    color: $white-color;
    cursor: pointer;

    &__arrow {
      flex: 2;

      & img {
        transition: transform 0.4s ease-out;
        width: 12px;
        height: 20px !important;
      }

      &.active {
        & img {
          transform: rotate(-180deg);
        }
      }
    }
    &__text {
      flex: 1;
    }
    a {
      color: $white-color;
    }

    @media all and (max-width:#{$tablet}) and (min-width:998px) {
      padding: 0;
      margin-left: 20px;
      margin-right: 20px;
    }
    @media all and (max-width:998px) and (min-width:#{$mobile}) {
      margin-left: 0px;
    }

    @media all and (min-width:#{$desktop}) {
      padding: 0;
      margin-left: 25px;
      margin-right: 25px;
    }
  }
  &__panel {
    display: none;
  }
}
.main-nav-mobile {
  @media all and (max-width: 768px) {
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0%;
    width: 100vw;
    height: 100%;
    min-width: 170px;
    z-index: -1;
    // pointer-events: none;
    overflow: hidden;
    display: none;

    .main-nav-mobile__panel {
      display: block;
      position: absolute;
      right: 0px;
      top: 0;
      width: 1000px;
      height: auto;
      transform: translateX(1150px);
      transform-origin: top right;
      background: $dark-color !important;
      transition: 0.4s transform ease-in-out;

      ul {
        padding-top: 80px;
        // padding-right: 30vw;
      }
    }
    .nav-item {
      padding-top: 8px;
      // padding-bottom: 8px;
      width: 100%;
      padding-right: 100px;
    }

    .nav-link {
      text-align: right;
      width: 170px;
    }
    & .submenu {
      position: relative !important;
      width: 100%;
      padding-right: 16px;

      & li {
        padding-right: 100px;
        padding-top: 8px;
        padding-bottom: 8px;
        & a {
          padding-right: 34px;
          text-align: right;
          display: block;
          width: 170px;
        }
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 8px;
        }
      }
    }
  }
}
.nav-item-active {
  &::after {
    content: "";
    display: block;
    width: 70%;
    height: 2px;
    background: $green-color;
    margin: 0 auto;
    // margin-left: auto;
    // transform: translateX(25%);

    @media all and (max-width: 768px) {
      display: none;
    }
  }
  @media all and (max-width: 768px) {
    background: $green-color;
  }
}
.main-nav-mobile-active {
  // transform: translateX(0px);
  // display: block;
  z-index: 99999999999;
  .main-nav-mobile__panel {
    transform: translateX(800px);
    z-index: 99999999999;
    overflow-y: scroll;
  }
}
@media all and (max-width: 768px) {
  .main-nav-mobile-active {
    background: rgba(0, 0, 0, 0.45);
    opacity: 1;
    z-index: 1000;
  }
}
.navbar-fixed-top {
  transition: background-color 200ms linear;
}
.navbar-fixed-top.scrolled {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #1e2c37 !important;

  z-index: 999;
  min-height: 80px;
  height: 80px;

  & img {
    height: 80px;
  }

  .main-nav {
    min-height: 80px;
  }
}
