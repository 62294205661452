.custom-card {
  width: 100%;
  border-radius: 22px;
  box-shadow: 0 0 21px rgba(0, 0, 0, 0.16);
  min-height: 139px;
  margin-bottom: 33px;
  overflow: hidden;

  @media all and (max-width: 768px) {
    min-height: 300px;
    height: auto;
  }
  &__intro {
    color: rgba(9, 70, 131, 0.6);
  }
  &__title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 7px;
  }
  &__text {
    color: rgba(9, 70, 131, 0.6);
    font-size: 14px;
    line-height: 23px;
  }

  &__button {
    background: none;
    box-shadow: none;
    border: none;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    right: 0;
    bottom: 5%;
    width: 88px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media all and (max-width: 768px) {
      bottom: 2%;
    }

    &::after {
      content: url("../assets/arrow-down.svg");
      display: block;
      margin-left: 10px;
      width: 8px;
      height: 13.5px;
    }
  }
  &--expanded {
    & .custom-card__expand-text {
      display: block;
    }

    & .custom-card__button {
      color: #88e7cf;
    }

    & .custom-card__button::after {
      content: url("../assets/arrow-up.svg");
      display: block;
    }
  }

  &__expand-text {
    display: none;
  }
}
