$white-color: #fff;
$green-color: #91b508;
$dark-color: #1e2c37;
$white-10: rgba(255, 255, 255, 0.1);
$white-60: rgba(255, 255, 255, 0.6);
$gray-60: rgba(30, 44, 55, 0.6);
$gray-12: rgba(0, 0, 0, 0.12);

// max-width

$desktop: 1920px; //1200 +
$tablet: 1200px; //768-1200
$mobile: 768px; //0-768

$mq-desktop: "(max-width: #{$desktop})";
$mq-tablet: "(max-width: #{$tablet})";
$mq-mobile: "(max-width: #{$mobile})";

// @media all and #{$mq-tablet} {
//     background: none;
//   }
