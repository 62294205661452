.header {
  height: 100vh;
  height: 900px;
  background-size: cover;
  background-position: 50% 80%;
  background-repeat: no-repeat;
  color: $white-color;
  font-weight: $w-bold;
  overflow: hidden;

  @media all and #{$mq-mobile} {
    height: 100vh;
    min-height: 1024px;
  }
  @media all and #{$mq-mobile} {
    min-height: 720px;
  }

  &-top {
    min-height: 130px;
    background-color: transparent;

    @media all and #{$mq-tablet} {
      height: 109px;
      min-height: auto;
    }
    @media all and #{$mq-mobile} {
      height: 74px;
      min-height: auto;
    }
  }

  &-content {
    min-height: 770px;

    @media all and #{$mq-tablet} {
      height: calc(100vh - 109px);
    }
    @media all and #{$mq-mobile} {
      height: calc(100vh - 74px);
    }
  }
  &__title {
    font-size: $f60;
    text-transform: $text-uppercase;
    line-height: $l80;
    font-weight: $w-bold;

    @media all and #{$mq-mobile} {
      font-size: 37px;
      line-height: 45px;
    }
  }
  &__label {
    background: $green-color;
    font-weight: $w-bold;
    width: 68px;
    height: 25px;
    margin-left: -2px;
    text-transform: uppercase;
    font-size: 16px;

    @media all and #{$mq-mobile} {
      margin-bottom: 15px;
    }
  }
  &__line {
    margin: 15px 0;

    @media all and #{$mq-mobile} {
      visibility: hidden;
    }
  }
  &__line-down {
    @media all and #{$mq-mobile} {
      visibility: hidden;
    }
  }
  &__line-up {
    @media all and #{$mq-mobile} {
      visibility: hidden;
    }
  }
  &__aside {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    @media all and #{$mq-mobile} {
      display: none;
    }
  }
  &__aside-film {
    position: absolute;
    bottom: 49px;
    width: 100%;
    height: 46px;

    & p:first-child {
      padding-bottom: 5px;
      font-size: $f10;
      text-transform: $text-uppercase;
      color: $white-60;
    }
    & p:last-child {
      font-size: $f22;
      color: $white-color;
    }
  }
  &-logo img {
    @media all and #{$mq-mobile} {
      min-width: 62px;
    }
  }
  &__col {
    padding: 0;
  }
}
