.page-home {
  & .header {
    background-image: url("../assets/home-header.jpg");
  }
  .section-1 {
    padding: 111px 0 97px 0;

    @media all and (max-width:#{$tablet}) {
      padding: 47px 0 77px 0;
    }
    &__btn {
      background: none;
      border: none;
      color: $green-color;
      text-transform: $text-uppercase;
      font-weight: $w-bold;
    }
    & > .container {
      @media all and (min-width:#{$desktop}) {
        margin: 0;
        max-width: 100vw !important;
        padding: 0;
      }
    }

    @media all and (max-width:#{$mobile}) {
      padding: 34px 0 58px 0;
    }
    .container {
    }
  }
  .section-2 {
    padding: 0 0 74px 0;

    @media all and (max-width:#{$tablet}) {
      padding: 0 0 85px 0;
    }
    @media all and (max-width:#{$mobile}) {
      padding: 0 0 70px 0;
    }

    &__col-icon {
      margin-bottom: 10px;

      @media all and (max-width:#{$mobile}) {
        margin-bottom: 8.5px;
      }
    }
    &__col-icon--1 {
      max-width: 50px;
      height: 47px;
    }
    &__col-number {
      font-size: $f46;
      font-weight: $w-bold;

      @media all and (max-width:#{$tablet}) {
        font-size: 40px;
      }
      @media all and (max-width:#{$mobile}) {
        margin-left: 20px;
      }
    }
    &__col-text {
      font-size: $f17;
      margin-left: 14px;
      font-weight: $w-bold;
      line-height: 20px;

      @media all and (max-width:#{$mobile}) {
        font-size: 13px;
        line-height: 16px;
        margin-left: 22px;
      }
    }
    &__col {
      @media all and (max-width:#{$mobile}) {
        margin-bottom: 50px;
        padding: 0;

        &--last {
          margin-bottom: 0;
        }
      }
    }

    &__row {
      @media all and (max-width:#{$tablet}) {
        margin-bottom: 60px;
      }
      @media all and (max-width:#{$mobile}) {
        margin-bottom: 0px;
      }
    }
  }
  .section-3 {
    &__row {
      margin-bottom: 30px;

      @media all and (max-width:#{$mobile}) {
        margin-bottom: 0;
      }
    }
    &__element-2 {
      position: absolute;
      top: 50%;
      right: -160px;
      @include center-vertical;
      z-index: -1;

      @media all and (max-width:#{$mobile}) {
        display: none;
      }
    }

    &__element-4 {
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translate(-40%, -50%);
      z-index: -1;

      @media all and (max-width:#{$mobile}) {
        transform: translate(-30%, -50%);
      }
    }

    .small-card {
      max-width: 370px;

      @media all and (max-width:#{$tablet}) {
        max-width: 337px;
      }

      @media all and (max-width:#{$mobile}) {
        width: 100%;
      }
    }
    .element-arrow-bottom-right {
      bottom: -25px;
    }
  }
  .section-4 {
    padding: 219px 0 126px 0;

    @media all and (max-width:#{$tablet}) {
      padding: 164px 0 79px 0;
    }
    @media all and (max-width:#{$mobile}) {
      padding: 84px 0 96px 0;
    }
    &__element-7 {
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translate(-9%, 17%);
      z-index: -1;

      @media all and (max-width:#{$mobile}) {
        display: none;
      }
    }
  }
  .section-5 {
    padding: 94px 0 62px 0;
    background: url("../assets/section5-bg.jpg");
    background-size: cover;

    @media all and (max-width:#{$tablet}) {
      background-position: center right;
      padding: 30px 0 15px 0;
    }
    @media all and (max-width:#{$mobile}) {
      background-position: center left;
      padding: 42px 0 22px 0;
    }
    & .section-title {
      margin-bottom: 30px;

      @media all and (max-width:#{$tablet}) {
        margin-bottom: 15px;
      }
    }
    &__header {
      margin-bottom: 90px;

      @media all and (max-width:#{$tablet}) {
        margin-bottom: 15px;
      }
      @media all and (max-width:#{$mobile}) {
        margin-bottom: 30px;
      }
    }
    &__element-16 {
      transform: translate(50%, -50%);

      @media all and (max-width:#{$mobile}) {
        display: none;
      }
    }
    &__element-17 {
      transform: translateX(50%);
      position: absolute;
      right: 0;
      top: 20%;
    }

    &__card {
      border: 1px solid $green-color;
      padding: 21px 34px 10px 14px;
      height: 230px;
      margin-bottom: 46px;
      // max-width: 269px;

      @media all and (max-width:#{$tablet}) {
        background: rgba(30, 44, 55, 0.7);
        max-width: none;
        margin-bottom: 15px;
      }
      @media all and (max-width:#{$mobile}) {
        background: none;
        margin-bottom: 15px;
      }
      &-button {
        position: absolute;
        bottom: 10px;
        right: 19px;
        background: none;
        border: none;
        color: $green-color;
        font-weight: $w-bold;

        &:after {
          display: block;
          content: url("../assets/arrow-right.svg");
          width: 20px;
          height: 20px;
          margin-left: 8px;
        }
      }
      &-title {
        font-size: 18px;
        color: $green-color;
        margin-bottom: 10px;
      }
    }
  }
  .section-6 {
    padding: 116px 0 99px 0;

    @media all and (max-width:#{$tablet}) {
      padding: 87px 0 72px 0;
    }

    @media all and (max-width:#{$mobile}) {
      padding: 78px 0 76px 0;
    }
    &__element-18 {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;

      @media all and (max-width:#{$mobile}) {
        width: 130px;
        height: 130px;
      }
    }
    .element-arrow-bottom-left {
      bottom: -29px;
      left: -25px;
      width: auto;
      height: auto;
    }
    .element-arrow-bottom-right {
      bottom: -29px;
      right: -25px;
    }
    & .dashed-card {
      @media all and (max-width:#{$tablet}) {
        margin-bottom: 50px;
      }
      @media all and (max-width:#{$mobile}) {
        margin-bottom: 46px;
      }
    }
    & .dashed-card--last {
      margin-bottom: 0;
      & .dashed-card__arrow-top,
      .dashed-card__circle {
        @media all and (max-width:#{$tablet}) {
          display: none;
        }
      }
    }
  }
  .section-7 {
    height: 645px;
    width: 100%;
    background: url("../assets/map.jpg");
    background-size: cover;
    background-position: 50%;
  }
  .section-8 {
    background: $dark-color;
    padding: 57px 0 158px 0;
    color: $white-color;

    @media all and (max-width:#{$mobile}) {
      padding: 59px 0 76px 0;
    }

    &__title {
      margin-bottom: 80px;

      @media all and (max-width:#{$tablet}) {
        margin-top: 50px;
        margin-bottom: 30px;
      }
      @media all and (max-width:#{$mobile}) {
        margin-bottom: 43px;
      }
    }
    a {
      color: #fff;
      display: block;
    }
    &__icon {
      margin-right: 10px;

      @media all and (max-width:#{$tablet}) {
        margin-right: 19px;
      }
    }
    &__icon-img {
      margin-left: -40px;
      margin-right: 20px;

      @media all and (max-width:#{$tablet}) {
        margin-left: 0px;
      }
    }
    &__info {
      margin-left: 10px;
    }
    &__arrow-wrapper {
      height: 30px;
    }
    &__element-21 {
      bottom: 0;
      right: 0;
    }
    &__element-22 {
      left: 0;
      top: 5%;
      transform: translateX(-50%);

      @media all and (max-width:#{$tablet}) {
        top: 65%;
      }
      @media all and (max-width:#{$mobile}) {
        display: none;
      }
    }
    &__element-24 {
      right: -20px;
      top: 0%;

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    &__element-25 {
      right: 0;
      top: 0;
      transform: translate(50%, -50%);
    }
    .element-arrow-top-left {
      @media all and (max-width:#{$tablet}) {
        display: none;
      }
      @media all and (max-width:#{$tablet}) {
        display: block;
      }
    }
    .small-card--button {
      @media all and (max-width:#{$tablet}) {
        height: 70px;
      }
    }
    &__recaptcha {
      height: 70px;

      @media all and (max-width:#{$mobile}) {
        display: none;
      }
    }
    & .element-arrow-top-left {
      left: -15px;
    }
    & .small-card--button {
      background: white;
      margin-bottom: 0px;
      text-align: center;

      @media all and (max-width:#{$mobile}) {
        height: 46px;
      }
    }
    & .mb-30px {
      @media all and (max-width:#{$mobile}) {
        margin-bottom: 15px;
      }
    }
    .element-arrow-top-left {
      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    & .form__input {
      color: #fff;

      @media all and (max-width:#{$mobile}) {
        color: $dark-color;
      }
    }
  }
}
