.page-servieces {
  overflow-x: hidden;
  &__header {
    background-image: url("../assets/servieces-header.jpg");
  }

  .section-1 {
    padding: 91px 0 0 0;
    color: $gray-60;

    @media all and (max-width:#{$tablet}) {
      padding-top: 165px;
    }
    @media all and (max-width:#{$mobile}) {
      padding-top: 60px;
    }
    & .section-title {
      margin-bottom: 97px;

      @media all and (max-width:#{$tablet}) {
        margin-bottom: 54px;
      }
      @media all and (max-width:#{$mobile}) {
        margin-bottom: 30px;
      }
    }
    &.section-title-container {
      & .element-arrow-top-left {
        left: -15px;
      }
      @media all and #{$mq-tablet} and (min-width:#{$mobile}) {
        width: 48%;
        margin: 0 auto;
      }
    }
    &__card {
      border: 1px dashed #91b508;
      background: white;
      @include box-shadow;
    }
    .section-1__card p:first-of-type {
      padding-bottom: 20px;
    }
    &__card-big {
      padding: 40px 70px 39px 84px;
      // z-index: 10;
      @include box-shadow;

      @media all and (max-width:#{$tablet}) {
        padding: 30px 28.5px 30px 39px;
      }
    }
    &__col-img {
      position: absolute;
      top: 0;
      z-index: -10;
      width: 100%;
      height: 100%;

      & > div {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    &__col-text {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      height: 100%;
      display: flex;
      align-items: center;

      @media all and (max-width:#{$mobile}) {
        top: 50%;
        height: auto;
      }
    }
    &__article {
      min-height: 470px;
      // overflow: hidden;
      margin-bottom: 140px;

      @media all and (max-width:#{$tablet}) {
        min-height: 345px;
        height: auto;
        margin-bottom: 50px;

        & .container {
          height: 100%;
        }
        &__card-big {
          height: 100%;
        }
      }
      .section-1__subtitle {
        padding-bottom: 3px;
      }
    }
    &__article-1 {
      .section-1__card-big {
        max-width: 670px;
      }
      .section-1__col-img {
        left: 0;

        & > div {
          background-image: url("../assets/servieces-1.jpg");
        }
      }
      .dashed-card__circle-to-right {
        display: none;
      }
      @media all and (max-width:#{$tablet}) {
        margin-bottom: 50.5px;

        .section-1__element-18 {
          display: none;
        }
        .section-1__element-32 {
          display: none;
        }
      }
      @media all and (max-width:#{$mobile}) {
        .dashed-card__circle-to-right {
          display: block;
        }
      }
    }
    &__subtitle {
      color: $green-color;
      font-size: 20px;
      font-weight: bold;
    }

    &__article-2 {
      &__card-big {
        max-width: 570px;
      }
      .section-1__col-img {
        left: 104%;
        transform: translateX(-100%);
        & > div {
          background-image: url("../assets/servieces-2.jpg");
        }
        @media all and (max-width:#{$mobile}) {
          left: 100%;
        }
      }
      @media all and (max-width:#{$tablet}) {
        margin-bottom: 57px;
      }
    }

    &__article-3 {
      .section-1__card-big {
        max-width: 670px;
      }
      .section-1__col-img {
        left: 0;
        & > div {
          background-image: url("../assets/servieces-3.jpg");
        }
      }
      @media all and (max-width:#{$tablet}) {
        margin-bottom: 55px;
      }
    }

    &__article-4 {
      &__card-big {
        max-width: 570px;
      }
      .section-1__col-img {
        left: 104%;
        transform: translateX(-100%);

        & > div {
          background-image: url("../assets/servieces-4.jpg");
        }
        @media all and (max-width:#{$mobile}) {
          left: 100%;
        }
      }
      @media all and (max-width:#{$tablet}) {
        margin-bottom: 53px;
      }
    }
    &__article-5 {
      margin-bottom: 114px;
      &__card-big {
        max-width: 670px;
      }
      .section-1__col-img {
        left: 0;

        & > div {
          background-image: url("../assets/servieces-5.jpg");
        }
      }
      .element-arrow-top-left {
        top: -40px;
      }
      @media all and (max-width:#{$tablet}) {
        margin-bottom: 68px;
      }
    }

    &__article-6 {
      margin-bottom: 93px;
      &__card-big {
        max-width: 570px;
      }
      .section-1__col-img {
        left: 104%;
        transform: translateX(-100%);

        & > div {
          background-image: url("../assets/servieces-6.jpg");
        }
        @media all and (max-width:#{$mobile}) {
          left: 100%;
        }
      }
      @media all and (max-width:#{$tablet}) {
        margin-bottom: 62px;
      }
    }

    &__element-18 {
      position: absolute;
      top: 0;
      right: 30%;
      transform: translate(50%, -50%);
      z-index: -1;

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    &__element-18-rotate {
      position: absolute;
      bottom: -21%;
      left: 80%;
      transform: rotate(136deg);
      z-index: -1;
      width: 1;
      width: 210px;
    }
    &__element-32 {
      position: absolute;
      bottom: 0;
      left: 0%;
      transform: translate(30%, 40%);
      z-index: -1;
    }
    &__element-32-rotate {
      position: absolute;
      top: -38%;
      left: 32%;
      z-index: -1;
      transform: rotate(180deg);

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    &__element-34 {
      position: absolute;
      bottom: 0;
      left: 25%;
      transform: translate(-60%, 20%) rotate(5deg);
      z-index: -1;

      @media all and (max-width:#{$mobile}) {
        display: none;
      }
    }
    &__element-34-rotate {
      position: absolute;
      bottom: -50%;
      left: -7%;
      z-index: -1;
      transform: rotate(-32deg);
      z-index: -12;

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    &__element-61 {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(11%, 28%);
      z-index: -100;
    }
    &__element-33 {
      transform: translate(60%, -70%);

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    &__element-36 {
      transform: translate(60%, -40%);

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    &__element-37 {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -40%);
      z-index: -1;

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    &__element-40 {
      display: none;

      @media all and (max-width:#{$tablet}) {
        position: absolute;
        left: 0%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: block;
        z-index: -1;
      }
      @media all and (max-width:#{$mobile}) {
        display: none;
      }
    }
    &__element-40-rotate {
      display: none;

      @media all and (max-width:#{$tablet}) {
        position: absolute;
        right: 0%;
        top: 50%;
        transform: translate(50%, -50%);
        display: block;
        z-index: -1;
      }
      @media all and (max-width:#{$mobile}) {
        display: none;
      }
    }
    &__element-62 {
      position: absolute;
      right: 22%;
      bottom: 0;
      transform: translate(0%, 26%);
      display: block;
      z-index: -1;

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }

    &__element-41 {
      display: none;

      @media all and (max-width:#{$tablet}) {
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translate(-50%, 50%);
        display: block;
        z-index: -11;
      }
    }
    &__element-42 {
      display: none;

      @media all and (max-width:#{$tablet}) {
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translate(50%, -50%);
        display: block;
        z-index: -11;
      }
    }
    &__element-43 {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -11;
    }
    & article .element-arrow-top-left {
      left: -25px;
    }
    .element-arrow-bottom-right {
      display: none;

      @media all and (max-width:#{$mobile}) {
        display: block;
      }
    }
    @media all and (max-width:#{$tablet}) {
      .dashed-card__circle-top-right,
      .dashed-card__circle-to-right,
      .dashed-card__circle-top,
      .dashed-card__circle {
        z-index: -1;
      }
      .element-arrow-top-left,
      .element-arrow-top-right {
        display: none;
      }
    }
  }
  .section-2 {
    padding-top: 0;
  }

  &-subpage {
    & .section-1 {
      p {
        padding-bottom: 20px;
        color: rgba(30, 44, 55, 0.6);
        line-height: 24px;
      }

      padding: 81px 0 69px 0;

      @media all and (max-width:#{$tablet}) {
        padding: 81px 0 52px 0;
      }
      @media all and (max-width:#{$mobile}) {
        padding: 61px 0 22px 0;
      }
    }
  }
}
