*,
*:before,
*:after {
  box-sizing: border-box;
}
*,
h1,
h2,
h3,
h4,
h5,
p,
address {
  padding: 0;
  margin: 0;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}
