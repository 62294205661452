.page-about {
  overflow-x: hidden;
  &__header {
    background-image: url("../assets/about-header.jpg");
    // background-size: cover;
    // background-position: bottom center;
  }
  .section-1 {
    padding: 100px 0 130px 0;

    &__column-small {
      padding-top: 150px;

      @media all and #{$mq-tablet} {
        text-align: center;
        padding-top: 80px;
      }
    }
    &__element-26 {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(50%, -50%);

      @media all and #{$mq-mobile} {
        display: none;
      }
    }
    &__element-arrow-top-left {
      top: -50px;
      left: -50px;

      @media all and #{$mq-tablet} {
        display: none;
      }
    }
  }
  .section-2 {
    padding-bottom: 131px;
    &__title-container {
      @media all and #{$mq-tablet} {
        width: 21%;
        margin: 0 auto;
      }
      @media all and #{$mq-mobile} {
        width: auto;
        margin: 0 auto;
      }
    }
    &__title {
      margin-bottom: 50px;
    }
    &__dashed-card {
      height: 158px;
      padding: 15px;
      width: 100%;
      margin-bottom: 27px;
      text-align: center;
      @include box-shadow;

      @media all and #{$mq-tablet} {
        height: 158px;
        min-height: auto;
      }
    }

    &__dashed-card-title {
      font-size: 17px;
      font-weight: bold;
      line-height: 20px;
      margin-top: 20px;
    }
    &__element-7 {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateY(43%);
      z-index: -100;

      @media all and #{$mq-mobile} {
        display: none;
      }
    }
    &__element-27 {
      position: absolute;
      top: 50%;
      left: -10%;
      z-index: -100;
      transform: translateY(-50%);

      @media all and #{$mq-mobile} {
        display: none;
      }
    }
    .dashed-card__circle-to-right {
      @media all and #{$mq-tablet} {
        display: none;
      }
    }
    &__element-28 {
      position: absolute;
      bottom: 0;
      right: 0%;
      z-index: -100;
      transform: translate(10%, 15%);

      @media all and #{$mq-mobile} {
        display: none;
      }
    }
    &__element-29 {
      position: absolute;
      bottom: 0;
      right: 0%;
      z-index: -100;
      transform: translate(96%, 32%);
      width: 65%;
      min-width: 290px;

      @media all and #{$mq-mobile} {
        display: none;
      }
    }
    .element-arrow-bottom-right {
      position: absolute;
      bottom: -25px;
      right: -25px;
    }
  }
  .section-3 {
    padding-bottom: 51px;
  }
}
