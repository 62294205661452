.dashed-card {
  height: 300px;
  background: $white-color;
  border: 1px dashed $green-color;
  padding: 53px 22px 11px 38px;
  width: 350px;
  box-sizing: border-box;
  @include box-shadow;
  margin-bottom: 30px;

  @media all and #{$mq-tablet} {
    width: 100%;
    min-height: 255px;
  }
  @media all and #{$mq-mobile} {
    margin-bottom: 40px;
    height: auto;
    width: 100%;
  }

  &__title {
    margin-bottom: 3px;
  }

  &__logo {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(21%, -50%);
    width: 116px;
    height: 116px;

    @media all and #{$mq-tablet} {
      width: 75px;
      height: 75px;
      transform: translate(21%, -27%);
    }
  }
  &__circle {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-50%, 50%);
  }
  &__circle-to-right {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(50%, 50%);
  }
  &__circle-top {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
  &__circle-top-right {
    position: absolute;
    top: 0%;
    right: 0;
    transform: translate(50%, -50%);

    @media all and (max-width:#{$mobile}) {
      display: none;
    }
  }
  &__arrow-top {
    position: absolute;
    top: 0%;
    left: 60%;
    transform: translate(-50%, -50%);

    @media all and (max-width:#{$mobile}) {
      display: none;
    }
  }

  &--small {
    height: 182px;
    padding: 34px 15px 18px 38px;
    // width: 370px;
    width: 100%;

    @media all and #{$mq-tablet} {
      min-height: 166px;
    }
    @media all and #{$mq-mobile} {
      height: auto;
    }
  }
  &__btn-read-more {
    background: none;
    border: none;
    color: $gray-60;
    outline: none;

    &:after {
      display: block;
      content: url("../assets/arrow-right.svg");
      height: 20px;
      padding-left: 15px;
    }
    &:hover {
      color: $gray-60;
    }
  }
}
