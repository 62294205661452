.page-realisations {
  overflow: hidden;
  & .header__label {
    width: 103px;
  }
  &__header {
    background-image: url("../assets/realisations-header.jpg");
  }
  .section-1 {
    padding: 81px 0 69px 0;

    @media all and (max-width:#{$tablet}) {
      padding: 81px 0 52px 0;
    }
    @media all and (max-width:#{$mobile}) {
      padding: 61px 0 22px 0;
    }

    &__element-30 {
      position: absolute;
      top: 0;
      left: 70%;
      transform: translate(-50%, -50%);
      z-index: -1;

      @media all and #{$mq-tablet} {
        display: none;
      }
    }
    &__title {
      margin-bottom: 64px;

      @media all and (max-width:#{$tablet}) {
        margin-bottom: 50px;
      }
    }
    &__title-container {
      width: min-content;
      white-space: nowrap;
    }
    &__element-2 {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: -1;
      transform: translate(50%, 50%);
      width: 90%;

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    .element-arrow-bottom-right {
      bottom: -25px;
      right: -15px;
    }
    &__element-44 {
      position: absolute;
      left: 0;
      top: 50%;
      z-index: -1;
      transform: translate(-20%, -35%);
      width: 23%;

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    & .hover-card {
      height: 180px;

      @media all and (max-width:#{$tablet}) {
        height: 300px;
      }

      @media all and (max-width: 992px) {
        height: 250px;
      }

      @media all and (max-width: #{$mobile}) {
        height: auto;
      }

      & > div {
        height: 100%;
      }
      & .img-hover {
        height: 100%;
        width: 100%;
      }
    }
  }
}
