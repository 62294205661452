.menu-button {
  background: none;
  border: none;
  display: none;
  margin: 0 1rem 0 auto;
  z-index: 1001;

  @media all and (max-width: 998px) {
    display: block;
    order: 3;
    margin: 0;
    margin-left: 1rem;
  }
}
.menu-button-wrapper {
  top: 50px;
  left: 50px;
  border: none;
  z-index: 1;

  & span {
    display: flex;
    flex-direction: column;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #000;
    border-radius: 3px;
    z-index: 1;
  }
}
