.accordion {
  & .card {
    border: none;
    margin: 23px 0;
    box-shadow: -7px 7px 70px rgba(0, 0, 0, 0.12);
  }

  & .card-body {
    margin-top: 23px;
    padding: 28px 24px 25px 31px;
    @include box-shadow;
    line-height: 24px;
    color: $gray-60;

    &-title {
      color: $dark-color;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    &-subtitle {
      font-size: 16px;
      font-weight: bold;
      color: $dark-color;
      margin: 15px 0 10px 0;
    }
    ul {
      margin-left: 15px;
    }
  }
  .btn-hide {
    color: $gray-60;
    background: none;
    display: flex;
    border: none;
    margin: 25px 20px;

    &::after {
      content: url("../assets/arrow-up.svg");
      display: block;
      margin-left: 5px;
    }
  }
  & .card-header {
    border: none;
    background: $dark-color;
    height: 83px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;

    & h3 {
      font-weight: 700;
      font-size: 22px;
    }
    & .btn-link,
    & .btn-link:hover {
      margin-bottom: 0;
      text-decoration: none;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.7);
      display: flex;
      align-items: center;

      &::after {
        content: url("../assets/arrow-down.svg");
        display: block;
        margin-left: 5px;
      }
    }
  }
  &-green {
    .card-body-title {
      color: $green-color;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    .card-header {
      background: $green-color;
    }
  }
  .card-item-circle {
    width: 6px;
    height: 6px;
    background: $green-color;
    margin-right: 14px;
    border-radius: 50%;
  }
  .card-item-number {
    color: $green-color;
    margin-right: 11px;
    width: 15px;
    height: 6px;
    font-weight: bold;
  }
}
