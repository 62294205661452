.hover-card {
  font-weight: bold;
  color: $green-color;
  cursor: pointer;
  position: relative;

  & .overlay-card {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background: rgba(0, 0, 0, 0.64);
    z-index: 1;
    opacity: 0;
    transition: 0.3s opacity ease-out;
  }
  p {
    font-size: 22px;
    margin-left: 30px;

    @media all and (max-width:#{$tablet}) {
      font-size: 13px;
      margin-left: 18px;
    }
    @media all and (max-width:#{$mobile}) {
      font-size: 10px;
      margin-left: 16px;
    }
  }
  & .img-hover {
    pointer-events: unset;
    transform: scale(1);
    overflow: hidden;
    transition: 0.3s all ease-in-out;
  }
  // & img:hover {
  //   transform: scale(1.1);
  // }
  &__element-5 {
    position: absolute;
    bottom: 10px;
    left: 15px;
    z-index: 2;
    width: 100%;
  }
  & .hover-card__show {
    width: 90%;
  }
  &:hover {
    color: $green-color;

    & .hover-card__show p {
      transform: translateX(-4%);
      opacity: 1;
    }
    // & .hover-card__show img {
    //   width: 120px;
    //   opacity: 1;

    //   @media all and (max-width:#{$tablet}) {
    //     width: 72px;
    //   }
    //   @media all and (max-width:#{$mobile}) {
    //     width: 63px;
    //   }
    // }
    & .img-hover {
      transform: scale(1.05);
    }
    & .overlay-card {
      opacity: 1;
    }
  }

  &__show img {
    // width: 0;
    // opacity: 0;
    // transition: 0.4s opacity ease-out;

    // @media all and (max-width:#{$tablet}) {
    //   width: 72px;
    //   opacity: 1;
    // }

    // @media all and (max-width:#{$mobile}) {
    //   width: 63px;
    // }
  }
  &__show p {
    transform: translateX(-100%);
    opacity: 0;
    transition: 0.4s transform ease-out;

    @media all and (max-width:#{$tablet}) {
      transform: translateX(-4%);
      opacity: 1;
    }
    @media all and (max-width:#{$mobile}) {
      font-size: 11px;
    }
  }
  &-small {
    margin-bottom: 40px;

    & img {
      pointer-events: unset;
    }

    p {
      font-size: 14px;
      @media all and (max-width:#{$mobile}) {
        font-size: 10px;
      }
    }

    @media all and (max-width:#{$tablet}) {
      margin-bottom: 15px;
    }
    .hover-card__show img {
      width: 0;
      @media all and (max-width:#{$mobile}) {
        width: 63px;
      }
    }

    &:hover {
      .hover-card__show img {
        width: 58px;

        @media all and (max-width:#{$mobile}) {
          width: 63px;
        }
      }
    }
  }
}
