.page-cookies {
  &__header {
    background-image: url("../assets/cookies-header.jpg");
  }
  p,
  li {
    margin-bottom: 16px;
  }
  & .header__line-up {
    margin-bottom: 15px;
  }
  & .header__line-down {
    margin-top: 10px;
  }
  & .header__label {
    width: 160px;
    align-self: flex-start;
  }
  & .section-1 {
    padding: 77px 0 119px 0;

    @media all and (max-width:#{$tablet}) {
      padding: 50.5px 0 60.6px 0;
    }
    @media all and (max-width:#{$mobile}) {
      padding: 43.5px 0 45px 0;
    }
    &__element-54 {
      position: absolute;
      left: 0;
      top: 40%;
      transform: translate(-50%, -50%);

      @media all and (max-width:#{$tablet}) {
        top: 80%;
        transform: translate(-50%, -50%);
      }
      @media all and (max-width:#{$mobile}) {
        display: none;
      }
    }
    &__element-56 {
      position: absolute;
      right: 0;
      bottom: 10%;
      transform: translateX(50%);

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    &__element-57 {
      position: absolute;
      right: 0;
      top: 40%;
      transform: translateX(50%);
      display: none;

      @media all and (max-width:#{$tablet}) {
        display: block;
      }
      @media all and (max-width:#{$mobile}) {
        display: none;
      }
    }
    &__element-59 {
      position: absolute;
      top: 0;
      left: 70%;
      transform: translate(-50%, -50%);
      z-index: -1;

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    .section-title {
      margin-bottom: 62px;

      @media all and (max-width:#{$tablet}) {
        margin-bottom: 17.5px;
      }
      @media all and (max-width:#{$mobile}) {
        margin-bottom: 37px;
      }
    }
  }
  .element-arrow-top-left {
    left: -15px;
    @media all and (max-width:#{$tablet}) {
      display: none;
    }
  }
}
