.small-card {
  min-height: 70px;
  padding: 13px 30px 12px 34px;
  @include box-shadow;
  border: 0;
  border-left: 4px solid $green-color;
  cursor: pointer;
  margin-bottom: 30px;

  @media all and #{$mq-mobile} {
    width: 100%;
  }

  &__title {
    text-transform: uppercase;
    font-size: $f10;
    font-weight: $w-bold;
    margin-bottom: 5px;
    color: $gray-60;
  }
  &__subtitle {
    font-weight: $w-bold;
    margin-bottom: 0;
    font-size: $f22;
  }
  &--button {
    width: 100%;
    height: 70px;
    padding: 10px 13px 9px 34px;
    min-height: auto;
    min-width: 170px;

    @media all and #{$mq-mobile} {
      height: 46px;
      min-width: auto;
    }
  }
}
