.social-links {
  &__item {
    background: $green-color;
    height: 70px;
    transform: translateX(87px);
    transition: 0.4s transform ease-out;
    width: 157px;
    font-size: $f17;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &:hover {
      transform: translateX(0px);
    }
  }
  &__link {
    color: $white-color;
    padding: 25px 7px 25px 21px;
    display: block;

    &:hover {
      color: $white-color;
    }
  }
  &__logo {
    margin-right: 28px;
    max-width: 28px;
    height: auto;
  }
}
