.subpage-realisations {
  & .section-1 {
    padding: 79px 0 107px 0;
    overflow: hidden;

    &__element-45 {
      position: absolute;
      left: 40%;
      top: 0;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
    &__element-46 {
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translate(50%, -10%);

      @media all and (max-width:#{$tablet}) {
        transform: translate(50%, 30%);
      }
      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    &__element-2 {
      transform: translate(50%, 60%) rotate(-90deg);
      position: absolute;
      bottom: 0%;
      right: 60%;
      z-index: -1;

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    &__title {
      margin-bottom: 57px;
    }
    &__article {
      margin-bottom: 68px;
      color: $gray-60;

      @media all and (max-width:#{$tablet}) {
        margin-bottom: 30px;
      }

      &-title {
        color: $green-color;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 5px;
      }

      &-icon {
        max-width: 35px;
        margin-right: 22px;
        display: block;
      }
      &-work {
        & img {
          margin-right: 8px;
          max-width: 50px;
        }
      }
    }
    &__list {
      padding-left: 15px !important;
      padding-top: 8px;
    }
    & .list__item {
      padding-bottom: 3px;
    }
  }
  &__carousel {
    margin-bottom: 69px !important;
    // max-height: 130px;
    // overflow: hidden;
    height: 136px;

    & img {
      width: 100%;
    }
    & .slick-slide {
      margin: 0 15px;
      height: 100%;
      width: 136px !important;

      @media all and (max-width: #{$tablet}) {
        max-width: 154px !important;
      }
      & .slider-img {
        height: 136px;
        width: 136px;
        background-size: cover;
        background-position: center;
      }
      & a {
        width: 100%;
      }
      &:first-of-type {
        margin: 0;
      }
    }
  }
  & .slick-next:before,
  &.slick-prev:before {
    color: $green-color;
    width: 14px;
    height: 20px;
  }
  & .slick-next:before {
    content: url("../assets/arrow-right.svg");
  }
  & .slick-prev:before {
    content: url("../assets/arrow-left.svg");
  }
  & .slick-current .slider-img {
    border: 3px solid $green-color;
  }
}
.superdrob-page {
  &__header {
    background-image: url("../assets/superdrob-header.jpg");
  }
}
