.mobile-ham {
  width: 50px;
  cursor: pointer;
  position: relative;
  z-index: 1001;

  &.show .first-bar {
    transform: rotate(45deg) translate(5px, 6px);
  }
  &.show .second-bar {
    display: none;
  }
  &.show .third-bar {
    transform: rotate(-45deg) translateY(0);
    width: 22px;
    margin: 5px;
  }
}

.mobile-bars {
  float: right;
  width: 30px;
  height: 30px;

  & span {
    background: $white-color;
    display: block;
    height: 3px;
    margin: 5px;
    transition: all 0.3s ease-in-out;
  }
  & .first-bar {
    width: 22px;
  }
  & .second-bar {
    width: 22px;
  }

  & .third-bar {
    margin: 5px 0px 5px 13px;
    width: 14px;
  }
}
