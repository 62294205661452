.page-references {
  &__header {
    background-image: url("../assets/references-header.jpg");
  }

  &__label {
    width: 109px;
  }
  & main {
    background: $dark-color;
  }
  &__slider {
    &::before {
      content: url("../assets/element47.svg");
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10000;
      transform: translate(-15%, -36%);
    }
    &::after {
      content: url("../assets/element24.svg");
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10000;
      transform: translate(10px, -30px);
    }
  }

  &__slide-content {
    // padding: 0 87px;
    background: #fff;
    height: 371px;
    border: 1px dashed $green-color;
    line-height: 24px;
    color: $gray-60;

    &-title {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 30px;
      color: $green-color;

      @media all and (max-width:#{$mobile}) {
        color: $dark-color;
      }
    }
    &-logo img {
      width: 194px;

      @media all and (max-width:#{$mobile}) {
        margin: 0 auto 25px auto;
      }
    }

    @media all and (max-width: 998px) {
      height: 500px;
    }
    @media all and (max-width:#{$mobile}) {
      padding: 36px 26px 40px 27px;
      height: auto;
      text-align: center;
    }
  }
  .section-1 {
    padding: 77px 0 88px 0;

    @media all and (max-width:#{$tablet}) {
      padding: 81px 0 90px 0;
    }
    @media all and (max-width:#{$mobile}) {
      padding: 59px 0 50px 0;
    }
    &__title {
      margin-bottom: 76px;

      @media all and (max-width:#{$tablet}) {
        margin-bottom: 62px;
      }
    }
    &__element-24 {
      right: -20px;
      top: 0%;
    }
    &__element-48 {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateY(-45%);

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    &__element-48-rotate {
      display: none;

      @media all and (max-width:#{$tablet}) {
        display: block;
        bottom: 0;
        right: 20%;
        position: absolute;
        transform: translateY(45%) rotate(180deg);
      }
      @media all and (max-width:#{$mobile}) {
        right: -10%;
      }
    }
    &__element-49 {
      position: absolute;
      top: 0;
      right: 0%;
      transform: translateX(50%);

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    &__element-50 {
      position: absolute;
      top: 60%;
      left: 0%;
      transform: translate(-50%, -50%);

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    .element-arrow-top-left {
      @media all and (max-width:#{$mobile}) {
        display: none;
      }
    }
  }
  &__slider-btns {
    @media all and (max-width:#{$tablet}) {
      display: none;
    }
  }
  &__slider-btn,
  &__slider-btn:focus {
    background: none;
    border: none;
    color: $green-color;
    font-size: 22px;
    font-weight: bold;
    margin-top: 40px;
    display: flex;
    align-items: center;
    outline: none;
  }
  &__slider-btn-prev {
    margin-right: 38px;
    &::before {
      content: url("../assets/arrow-left.svg");
      display: block;
      margin-right: 17px;
    }
  }
  &__slider-btn-next {
    &:after {
      content: url("../assets/arrow-right.svg");
      display: block;
      margin-left: 17px;
    }
  }
  & .footer {
    border-top: 1px solid $dark-color;
  }
}
