.footer {
  background: $dark-color;
  color: $white-color;
  min-height: 75px;
  height: auto;
  font-size: $f12;
  border-top: 1px solid $green-color;
  min-height: 77px;

  & a {
    color: $green-color;
    text-transform: uppercase;
    font-weight: $w-bold;
  }
  &__logo {
    min-width: 82px;
    min-height: 98px;
    margin-top: -20px;
    margin-right: 120px;
  }

  &__wrapper-text {
    flex: 3;
  }

  @media all and (max-width:#{$tablet}) {
    &__logo {
      margin-right: 18px;
      min-height: 110px;
      min-width: 92px;
    }
    &__wrapper-text {
      padding-top: 21px;
      padding-bottom: 7px;
      padding-right: 57px;
    }
    &__wrapper {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
  @media all and (max-width:#{$mobile}) {
    &__logo {
      min-width: 63px;
      min-height: 75px;
      margin-right: 15px;
      margin-top: -15px;
    }
    &__wrapper-text {
      padding-top: 16px;
      padding-bottom: 18px;
      padding-right: 33px;
    }
  }
}
