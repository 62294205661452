.send-us {
  min-height: 70px;

  @media all and #{$mq-mobile} {
    height: auto;
    box-shadow: none;
  }

  @include box-shadow;

  .section-title {
    margin-bottom: 0;
  }
  &__text {
    border: 1px dashed $green-color;
    border-right: none;

    @media all and #{$mq-mobile} {
      border-right: 1px dashed $green-color;
      min-height: 93px;
      @include box-shadow;
    }
  }
  & .small-card--button {
    height: 70px;
    box-shadow: none;
    background: #fff;
    margin: 0;
    color: $dark-color;

    @media all and #{$mq-mobile} {
      @include box-shadow;
      width: 100%;
    }
  }
}
