$base-font-family: "Cabin", sans-serif;

// font-sizes

$f12: 12px;
$f16: 16px;
$f17: 17px;
$f22: 22px;
$f10: 10px;
$f46: 46px;
$f60: 60px;

$w-regular: 400;
$w-bold: 700;

$text-uppercase: uppercase;

// Line-Height
$base-line-height: 24px;
$l80: 80px;

body {
  font-size: $f16;
  line-height: $base-line-height;
  font-family: $base-font-family;
  color: $dark-color;
}
