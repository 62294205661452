.page-jobs {
  &__header {
    background-image: url("../assets/job-header.jpg");
    // background-position: center;
    background-position: 50% 10%;
  }
  &__label {
    width: 70px;
  }
  .section-1 {
    overflow: initial;
    padding: 77px 0 108px 0;

    @media all and (max-width:#{$tablet}) {
      padding: 50.5px 0 59px 0;
    }
    @media all and (max-width:#{$mobile}) {
      padding: 40.5px 0 50px 0;
    }

    .list__item::before {
      width: 11px;
    }
    &__element-51 {
      position: absolute;
      top: 0;
      left: 70%;
      transform: translate(-50%, -50%);
      z-index: -1;

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    &__title {
      margin-bottom: 20px;

      @media all and (max-width:#{$tablet}) {
        margin-bottom: 15px;
      }
    }
    &__subtitle {
      margin-bottom: 76px;

      @media all and (max-width:#{$tablet}) {
        margin-bottom: 34.5px;
      }
      @media all and (max-width:#{$mobile}) {
        margin-bottom: 31.5px;
      }
    }

    &__element-55 {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(50%, -50%);

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    .element-arrow-top-left {
      left: -15px;
    }
  }
  .section-2 {
    padding: 0 0 55px 0;

    & .form__label {
      color: $gray-60;
    }
    & .new-button {
      display: inline-block;
      padding: 4px 42px;
      cursor: pointer;
      background-color: $green-color;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      margin-top: 5px;
    }
    & input[type="file"] {
      position: absolute;
      z-index: -1;
      top: 6px;
      left: 0;
      font-size: 15px;
      color: rgb(153, 153, 153);
      margin-left: 78px;
    }
    & .button-wrap {
      position: relative;
    }
    &__element-53 {
      position: absolute;
      right: 0;
      bottom: 10%;
      transform: translateX(50%);

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    &__element-54 {
      position: absolute;
      left: 0;
      top: 30%;
      transform: translate(-50%, -50%);
      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    .element-arrow-top-left {
      left: -25px;
    }
    .small-card--button {
      background: white;
    }
  }
}
