html {
  overflow-x: hidden;
}

body {
  width: 100%;
  overflow-x: hidden;
}
.section {
  padding: 70px 0;
}
.text-uppercase {
  text-transform: uppercase;
}
.bold {
  font-weight: $w-bold;
}
.f-size-22 {
  font-size: $f22;
}

.f-size-16 {
  font-size: $f16;
}
.text-green {
  color: $green-color;
}

.text-light-gray {
  color: $gray-60;
}
.mb-15px {
  margin-bottom: 15px;
}
.mb-30px {
  margin-bottom: 30px;

  // @media all and (max-width:#{$mobile}) {
  //   margin-bottom: 0px;
  // }
}
.mt-30px {
  margin-top: 30px;
}
.ml-30px {
  margin-left: 30px;
}
.mr-30px {
  margin-right: 30px;
}
.mb-55px {
  margin-bottom: 55px;
}
.mx-25px {
  margin: 0 25px;
}
.mt-20px {
  margin-top: 20px;
}
.pr-50px {
  padding-right: 50px;
}
.column-small {
  max-width: 370px !important;

  @media all and #{$mq-tablet} {
    max-width: 100% !important;
  }
}
.section-title-container {
  width: min-content;
  white-space: nowrap;

  .element-arrow-top-left {
    display: block !important;
    left: -15px;
  }
}
.section-title {
  font-size: $f22;
  font-weight: 700;
  margin-bottom: 30px;
}

.section-subtitle {
  // font-size: $font-size-section-subtitle;
  font-weight: 700;
  margin: 30px 0 15px 0;
}
.section-text {
  // color: $color-transparent;
  line-height: 23px;
}

// arrows decorative
.element-arrow-top-left {
  position: absolute;
  top: -25px;
  left: 0px;

  @media all and (max-width:#{$mobile}) {
    top: -15px;
    left: 0px;
  }
}
.element-arrow-top-right {
  position: absolute;
  top: -25px;
  right: -23px;

  @media all and (max-width:#{$mobile}) {
    top: -15px;
    right: 0px;
  }
}
.element-arrow-bottom-right {
  position: absolute;
  bottom: -15px;
  right: 0px;

  @media all and (max-width:#{$mobile}) {
    bottom: -15px;
    right: 0px;
  }
}
.element-arrow-bottom-left {
  position: absolute;
  bottom: -25px;
  left: -23px;

  // @media all and (max-width:#{$mobile}) {
  //   bottom: -15px;
  //   left: -15px;
  // }
  @media all and (max-width:#{$mobile}) {
    width: 53px;
    height: 27px;
  }
}
.element-top-right {
  position: absolute;
  top: 0px;
  right: 0px;
}
.element-top-left {
  position: absolute;
  top: 0px;
  left: 0px;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.list-item {
  padding-bottom: 5px;
}
.list-item-circle {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  background: #91b508;
  border-radius: 50%;
  margin-right: 15px;
}
img {
  pointer-events: none;
}
// @media (min-width: 360px) {
//   // .container {
//   //   // max-width: 297px !important;
//   //   max-width: 90% !important;
//   // }
// }
// @media (min-width: 576px) {
//   .container {
//     max-width: 540px !important;
//   }
// }
// @media (min-width: 768px) {
//   .container {
//     max-width: 711px !important;
//   }
// }
// @media (min-width: 992px) {
//   .container {
//     max-width: 960px !important;
//   }
// }
@media (min-width: 1200px) {
  .container {
    max-width: 1170px !important;
  }
}
.list__item {
  display: flex;
}
li span {
  display: block;
}
.circle {
  width: 7px;
  height: 7px;
  background: $green-color;
  border-radius: 50%;
  margin-right: 15px;
}

.form-success {
  color: #91b508;
  font-weight: bold;
}
.form-error {
  color: #ff4444d4;
  font-weight: bold;
}
button:disabled {
  opacity: 0.5;
}

button:focus,
input:focus,
textarea:focus {
  outline: none;
  border: 2px solid lightgray;
}
.confirm-more {
  display: none;
}
.btn-confirm-more {
  cursor: pointer;
}
