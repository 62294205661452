.page-privacy-policy {
  &__header {
    background-image: url("../assets/privacy-header.jpg");
    // background-position: 20%;
  }
  p,
  li {
    margin-bottom: 16px;
  }
  .header__line-up {
    margin-bottom: 15px;
  }
  .header__line-down {
    margin-top: 10px;
  }
  .header__label {
    width: 204px;
    align-self: flex-start;
  }
  .section-1 {
    padding: 77px 0 137px 0;

    @media all and (max-width:#{$tablet}) {
      padding: 50.5px 0 58px 0;
    }
    @media all and (max-width:#{$mobile}) {
      padding: 42px 0 45px 0;
    }

    &__title {
      margin-bottom: 62px;
      @media all and (max-width:#{$tablet}) {
        margin-bottom: 50.5px;
      }
      @media all and (max-width:#{$mobile}) {
        margin-bottom: 33.5px;
      }
    }
    &__element-54 {
      position: absolute;
      left: 0;
      top: 40%;
      transform: translate(-50%, -50%);

      @media all and (max-width:#{$tablet}) {
        // top: 80%;
        // transform: translate(-50%, -50%);
        display: none;
      }
      @media all and (max-width:#{$mobile}) {
        display: none;
      }
    }
    &__element-56 {
      position: absolute;
      right: 0;
      bottom: 10%;
      transform: translateX(50%);

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    &__element-51 {
      position: absolute;
      top: 0;
      left: 80%;
      transform: translate(-50%, -50%);
      z-index: -1;

      @media all and (max-width:#{$tablet}) {
        display: none;
      }
    }
    &__info {
      background: #fff;
      border: 1px dashed $dark-color;
      padding: 62px 70px;
      margin-bottom: 24px;
      color: $gray-60;

      @media all and (max-width:#{$tablet}) {
        margin-bottom: 48px;
        padding: 50px 70px;
      }
      @media all and (max-width:#{$mobile}) {
        margin-bottom: 50.5px;
        padding: 30px 27px;
      }
    }
    .element-arrow-top-left {
      left: -15px;
    }
  }
  .element-arrow-top-left {
    @media all and (max-width:#{$tablet}) {
      display: none;
    }
  }
}
