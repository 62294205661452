.form {
  &__input {
    width: 100%;
    height: 40px;
    background: $white-10;
    border: 1px solid $green-color;
    padding: 10px;

    @media all and (max-width:#{$mobile}) {
      background: $white-color;
    }
  }
  &__label {
    font-size: $f12;
    color: rgba(255, 255, 255, 0.68);
    margin-bottom: 6px;
  }
  &__group {
    margin-bottom: 10px;
  }
  &__textarea {
    min-height: 113px;
  }
  &__checkbox {
    margin-right: 8px;
    display: inline-block;
  }
  &__checkbox-field {
    margin: 20px 0 17px 0;
  }
  .error {
    color: #ff4444d4;
    font-weight: bold;
    margin-top: 5px;
    font-size: 11px;
    margin-bottom: -2px;
  }
  .error-field {
    transition: 0.6s color ease-out;
  }
  .form__checkbox-field .error {
    margin-top: 0;
    font-size: 16px;
    margin-bottom: 0;
  }
  .border-red {
    border: 1px solid #ff4444d4;
  }
}
